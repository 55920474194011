import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useLocalStorage } from "./localstorage.helper";
import { commonService } from "forms/helpers/common.service";
import { clearStore } from "utils/redux.utils";
import { useNavigate } from "react-router-dom";
import { IncidentReportingRole, projectEndURLName } from "utils/constant.utils";
import { parsePhoneNumberformat } from "layout/hooks/customHook";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  setMenuItemsRedux,
  setPopupItemsRedux,
  setStateListsRedux,
  setSubMenuItemsRedux,
  setUserRedux,
} from "store/slice/user.slice";
import menuItemsIR from "utils/menu-items";
import popupItemsIR from "utils/popup-item";

export const AuthContext: any = createContext({});

export const AuthProvider = ({ children }: any) => {
  const [token, setToken] = useLocalStorage(
    "at-ir-token",
    sessionStorage.getItem("at-ir-token")
  );
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user.user);
  const dispatch = useAppDispatch();

  const [countryCode] = useState<string>("US");

  useEffect(() => {
    if (token) {
      getUser({});
      getStateList();
    }
  }, [token]);

  const getStateList = async () => {
    try {
      const stateResponse: any = await commonService.postService(
        `/v1/public/state_list/${countryCode}`,
        {},
        false,
        projectEndURLName.digitalCredentialing
      );
      if (!!stateResponse) {
        let stateList: any = stateResponse.data.data;
        dispatch(setStateListsRedux(stateList));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const login = async (data: any) => {
    setToken(data?.token);
    await getUser({});
  };

  const logout = () => {
    clearStore();
    setToken(null);
    navigate("/login", { replace: true });
  };

  const getUser = async ({
    afterLoginNavigate,
  }: {
    afterLoginNavigate?: string;
  }) => {
    try {
      const response = await commonService.getServices(`/v1/auth/my_profile`);
      const userData = response?.data?.data;
      let roles = [];

      if (userData?.user?.user_badges) {
        roles = userData?.user?.user_badges.map((item: any) => item.badge.name);
      }

      const setUserData = {
        id: userData.id,
        firstname: userData?.firstname,
        lastname: userData?.lastname,
        email: userData?.user?.email,
        dob: userData?.dob,
        mobile_number:
          parsePhoneNumberformat(
            userData?.user?.country_code,
            userData?.user?.mobile_number
          ) || "",
        role: roles,
      };

      dispatch(setUserRedux(setUserData));

      if (roles.includes(IncidentReportingRole.ADMIN)) {
        if (afterLoginNavigate) {
          dispatch(setMenuItemsRedux(menuItemsIR[IncidentReportingRole.ADMIN]));

          const parentItem = menuItemsIR[
            IncidentReportingRole.ADMIN
          ][0]?.children.find(
            (i: any) =>
              i.children?.length > 0 &&
              i.activeItem?.includes(afterLoginNavigate)
          );

          if (parentItem) {
            dispatch(setSubMenuItemsRedux(parentItem.children));
          } else {
            dispatch(setSubMenuItemsRedux([]));
          }

          dispatch(
            setPopupItemsRedux(popupItemsIR[IncidentReportingRole.ADMIN])
          );
          navigate(afterLoginNavigate);
          return;
        }
        // if (isInitialRoute) {
        else {
          dispatch(setMenuItemsRedux(menuItemsIR[IncidentReportingRole.ADMIN]));
          dispatch(
            setSubMenuItemsRedux(
              menuItemsIR[IncidentReportingRole.ADMIN][0]?.children[1]?.children
            )
          );
          dispatch(
            setPopupItemsRedux(popupItemsIR[IncidentReportingRole.ADMIN])
          );
          navigate(`/services/${IncidentReportingRole.ADMIN}/dashboard`);
        }
      } else {
        window.location.href = `${process.env.REACT_APP_DC_REDIRECTION_URL}login?url=/profile/services_menu`;
      }
    } catch (error) {
      console.log(error);
      logout();
    }
  };

  const value = useMemo(
    () => ({ token, user, getUser, login, logout }),
    [token]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth: any = () => {
  return useContext(AuthContext);
};
