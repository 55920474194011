import { IPopupItems } from "interface/redux.interface";

export const adminPopupItem: IPopupItems[] = [
  // {
  //   path: `${process.env.REACT_APP_DC_REDIRECTION_URL}admin/profile`,
  //   title: "Profile",
  //   icon: "profile",
  //   isBadge:true
  // },
  {
    path: `/resetpassword`,
    title: "Reset Password",
    icon: "settings",
  },
  {
    title: "Logout",
    icon: "logout",
    isLogout: true,
  },
];
